import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import ApplyStyledBackgroundSection from "../components/apply-background-image"
import Seo from "../components/seo"

const Apply = () => (
  <Layout>
    <Seo title="Apply" />
    <ApplyStyledBackgroundSection />

  </Layout>
)

export default Apply
